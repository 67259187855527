<template>
  <div class="container">
    <div class="login_code_view"></div>
    <div class="login_bottom_bg">
      <van-tabs
        class="tabs_view"
        @click="onClick"
        v-model="active"
        color="#00B05A"
        title-active-color="#00B05A"
      >
        <van-tab title="验证码登录" style="background: white">
          <van-form style="margin: 40px 40px 0px 40px">
            <van-field
              label-class="field_view"
              v-model.trim="username"
              name="用户名"
              label="+63"
              placeholder="请输入手机号"
              label-width="48px"
            >
              <div
                style="display: flex; flex-direction: row"
                slot="label"
                @click="toCodeSelection(0)"
              >
                <span>{{ label }}</span>
                <van-icon name="arrow-down" style="margin-top: 6px; margin-left: 3px" />
              </div>
            </van-field>
            <van-field
              center
              clearable
              label="验证码"
              placeholder="请输入验证码"
              label-width="48px"
              v-model.trim="captchaCode"
            >
              <template #button>
                <van-button
                  class="get_code_btn"
                  size="small"
                  type="primary"
                  id="btn_code"
                  @click="captchaLogin(this)"
                >获取验证码</van-button>
              </template>
            </van-field>
            <div class="label_view" @click="agreementJump">
              <p>新用户登录即完成注册，代表同意</p>
              <p>《生鲜到家用户协议》</p>
            </div>
            <div style="margin: 30px 20px 0px 20px">
              <van-button
                round
                block
                type="info"
                color="linear-gradient(to right, #01B15B, #49D0A2)"
                @click="captchaLogin_"
              >同意并登录</van-button>
            </div>
          </van-form>
          <!-- <login-bottom></login-bottom> -->
        </van-tab>
        <van-tab style="background: white" title="密码登录">
          <van-form style="margin: 40px 40px 0px 40px">
            <van-field v-model.trim="username" name="用户名" placeholder="请输入手机号" label-width="48px">
              <div
                style="display: flex; flex-direction: row"
                slot="label"
                @click="toCodeSelection(1)"
              >
                <span>{{ label }}</span>
                <van-icon name="arrow-down" style="margin-top: 6px; margin-left: 3px" />
              </div>
            </van-field>
            <van-field
              v-model.trim="password"
              type="password"
              name="密码"
              label="密码"
              placeholder="请输入密码"
              label-width="48px"
            />
            <div class="forget_pwd_view">
              <van-button
                round
                block
                type="info"
                color="linear-gradient(to right, #01B15B, #49D0A2)"
                @click="login"
              >登录</van-button>
              <span
                @click="forgetPassword"
                style="color: #00b05a; font-size: 14px; margin-top: 10px"
              >忘记密码？</span>
            </div>
          </van-form>
          <!-- <login-bottom></login-bottom> -->
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import LoginBottom from "../../components/LoginBottom";
import { Toast } from "vant";
import {
  pwdLogin,
  updatePassword,
  captcha_sendSms
} from "@/service/api/index.js";
import { setToken } from "@/utils/auth.js";
export default {
  data() {
    return {
      username: "",
      password: "",
      captchaCode: "",
      captchaId: "",
      active: 0,
      time: true
    };
  },
  components: {
    LoginBottom
  },
  computed: {
    label: {
      get() {
        return this.$route.query.code || "+63";
      }
    },
    countryCode: {
      get() {
        return this.$route.query.countryCode || "PH";
      }
    },
    url() {
      return "123";
    },
    url2() {
      return function() {
        return "234";
      };
    }
  },
  mounted() {
    if (this.$route.query.option) {
      this.active = this.$route.query.option * 1;
    }
  },
  methods: {
    captchaLogin(e) {
      if (this.time) {
        captcha_sendSms({
          phone: this.username,
          areaCode: this.label.replace("+", ""),
          countryCode: this.countryCode
        }).then(res => {
          if (res.status == 200) {
            this.time = false;
            this.captchaId = res.data.id;
            var btn = document.querySelector(".get_code_btn");
            var sum = 60;
            var timer;
            timer = setInterval(() => {
              sum--;
              btn.innerHTML = sum + "s";
              if (sum === 0) {
                btn.innerHTML = "获取验证码";
                clearInterval(timer);
                this.time = true;
                sum = 60;
              }
            }, 1000);
          }
        });
      }
    },
    captchaLogin_() {
      if (this.username === "") {
        Toast.fail("请输入手机号");
        return;
      } else if (this.captchaCode === "") {
        Toast.fail("请输入验证码");
        return;
      }
      pwdLogin({
        areaCode: this.label.replace("+", ""),
        captchaCode: this.captchaCode,
        captchaId: this.captchaId,
        countryCode: this.countryCode,
        loginName: this.username
      })
        .then(res => {
          if (res.status === 200) {
            setToken(res.data, "token");
            this.$router.go(-1);
          } else {
            Toast.fail(res.message);
          }
        })
        .catch(err => {});
    },
    login() {
      if (this.username === "") {
        Toast.fail("请输入手机号");
        return;
      } else if (this.password === "") {
        Toast.fail("请输入密码");
        return;
      }
      pwdLogin({ loginName: this.username, password: this.password })
        .then(res => {
          if (res.status === 200) {
            setToken(res.data, "token");
            this.$router.go(-1);
          } else {
            Toast.fail(res.message);
          }
        })
        .catch(err => {});
    },
    onClick(name, title) {
      if (title === "验证码登录") {
      } else if (title === "密码登录") {
      }
    },
    toCodeSelection(option) {
      this.$router.push({
        path: "/areaCodeSelection",
        query: { option: option, form: "/login" }
      });
    },
    onSubmit(values) {
      this.login();
    },
    forgetPassword() {
      this.$router.push({ name: "ForgetPassword" });
    },
    agreementJump() {
      this.$router.replace({ name: "Agreement" });
    }
  }
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 768px) {
  .login_code_view {
    max-width: 540px;
    width: 540px;
    height: 327px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/new_login_bg.png");
    background-size: 540px 327px;
    background-repeat: no-repeat;
  }
}
@media screen and (min-width: 540px) {
  .login_code_view {
    max-width: 540px;
    width: 540px;
    height: 327px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/new_login_bg.png");
    background-size: 540px 327px;
    background-repeat: no-repeat;
  }
}
.container {
  width: 100%;
  height: 100%;
  position: relative;
  background: white;
}
.login_code_view {
  width: 100%;
  height: 327px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/new_login_bg.png");
  background-size: 100% 327px;
  background-repeat: no-repeat;
}
.login_bottom_bg {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0px;
  top: 285px;
  background: white;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
}
.tabs_view {
  margin-top: 30px;
}
.get_code_btn {
  background: white;
  color: #00b05a;
  border: 0px solid white;
}
.label_view {
  display: flex;
  flex-direction: row;
  font-size: 10px;
  margin-top: 10px;
  margin-left: 15px;
}

.field_view {
  color: #080808;
  font-size: 16px;
}

.label_view p:first-of-type {
  color: #3f3f3f;
}
.label_view p:last-of-type {
  color: #00b05a;
}
.forget_pwd_view {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 30px 20px 0px 20px;
}
</style>
