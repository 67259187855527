<template>
  <div>
    <van-nav-bar :fixed="true" class="nav_bar_view" left-arrow>
      <template #left>
        <img
          @click="returnPage"
          style="width: 18px; height: 18px"
          src="../../assets/black_return_view_bg.png"
          alt=""
        />
      </template>
      <template #title>
        <!-- <span style="color: black; font-size: 18px">详情</span> -->
        <span style="color: black; font-size: 18px">{{title}}</span>
      </template>
    </van-nav-bar>
    <div class="content" v-html="content">
        {{content}}
    </div>
    <!-- <div class="address_list_view">

    </div> -->
  </div>
</template>

<script>
import { Toast } from "vant";

import {
  imgApp,
  app2,
  receiptDelete,
} from "@/service/api/index.js";
import LoginVue from "../login/Login.vue";
export default {
  data() {
    return {
      activeIcon: "../../assets/address_check_bg.png",
      inactiveIcon: "../../assets/address_no_check_bg.png",
      fromPath: "",
      title:"",
      content:"",
    };
  },
  created(){
    //   meixiewan
      if(this.$route.params.title){
          this.title = this.$route.params.title;
          this.content = this.$route.params.content;
      }
      
  },
  mounted() {
  },
  methods: {
    returnPage() {
        this.$router.push("/home");
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 768px) {
  .nav_bar_view {
    max-width: 540px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  .address_list_view {
    max-width: 540px;
  }
}
.nav_bar_view {
  width: 100%;
  height: 44px;
  background: white;
  color: #00b05a;
}
.content{
  margin-top: 44px;
}
.address_list_view {
  max-width: 540px;
  width: 100%;
  margin-top: 45px;
  display: flex;
  background: white;
  flex-direction: column;
  align-items: center;
  .group_view {
    width: 100%;
    height: 1000px;
    .group_class_view {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 12px;
      height: 90px;
      width: 93%;
      border-bottom: 1px solid #f2f2f2;
      background: white;
      .img-icon {
        height: 20px;
        z-index: 10;
        margin-left: 12px;
      }
    }
  }

  .user_info_view {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 10px;
    .top_user_info_view {
      display: flex;
      flex-direction: row;
      align-items: center;
      span {
        font-size: 13px;
        color: #3f3f3f;
      }
      div {
        width: 24px;
        height: 14px;
        background: #df3436;
        color: white;
        text-align: center;
        margin-left: 13px;
        line-height: 14px;
        padding: 2px;
        font-size: 10px;
      }
    }
    .address_tv_view {
      font-size: 12px;
      color: #999999;
      margin-top: 5px;
      margin-right: 38px;
    }
  }
  .two_button_view {
    display: flex;
    flex-direction: column;
    width: 50px;
    img {
      width: 24px;
      height: 24px;
    }
    img:last-of-type {
      margin-top: 20px;
    }
  }
}
</style>
