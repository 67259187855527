<template>
  <div style="background: white; width: 100%; height: 100%">
    <div class="login_line_view">
      <span class="line_view"></span>
      <span class="text_line_view">第三方账号登录</span>
      <span class="line_view"></span>
    </div>
    <div class="wechat_view">
      <img @click="wxLogin" src="../assets/wechat_bg.png" alt="" />
      <span>未注册过的用户将直接创建账户</span>
    </div>
    <van-dialog v-model="show" show-cancel-button>
      <!-- <img  src="https://img01.yzcdn.cn/vant/apple-3.jpg" /> -->
      <div id="wxLogin"></div>
    </van-dialog>
  </div>
</template>

<script>
import "../utils/wxLogin";
import { login_user_callBack } from "@/service/api/index.js";
export default {
  data() {
    return {
      show: false,
      userAgent: "",
    };
  },
  created() {
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      this.userAgent = "IOS";
    } else if (/(Android)/i.test(navigator.userAgent)) {
      this.userAgent = "这是Android";
    } else {
      this.userAgent = "PC";
    }
  },
  methods: {
    wxLogin() {
      this.show = true;
      this.$nextTick(() => {
        // 官方文档地址: https://developers.weixin.qq.com/doc/oplatform/Website_App/WeChat_Login/Wechat_Login.html
        const wxLogin = new WxLogin({
          // true：手机点击确认登录后可以在 iframe 内跳转到 redirect_uri，false：手机点击确认登录后可以在 top window 跳转到 redirect_uri。默认为 false。
          self_redirect: false,
          // 第三方页面显示二维码的容器id
          id: "wxLogin",
          // 应用唯一标识，在微信开放平台提交应用审核通过后获得
          appid: "wx8f961b64a928c904",
          // 应用授权作用域，拥有多个作用域用逗号（,）分隔，网页应用目前仅填写snsapi_login即可
          scope: "snsapi_login",
          // 重定向地址，需要进行UrlEncode
          redirect_uri: encodeURIComponent(`http://h5.freshph.com`), //`${window.location.protocol}//${window.location.host}/wxLogin`
          // 用于保持请求和回调的状态，授权请求后原样带回给第三方。该参数可用于防止csrf攻击（跨站请求伪造攻击），建议第三方带上该参数，可设置为简单的随机数加session进行校验
          state: "wxRedirect",
          // 提供"black"、"white"可选，默认为黑色文字描述。详见文档底部FAQ
          // style: "",
          // 自定义样式链接，第三方可根据实际需求覆盖默认样式。详见文档底部FAQ
          // href: ""
        });
        console.log(wxLogin);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login_line_view {
  width: 302px;
  height: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: white;
  margin: 50px auto 19px auto;
}

.line_view {
  width: 60px;
  height: 1px;
  background: #76908e;
}

.text_line_view {
  font-size: 14px;
  color: #3f3f3f;
  font-family: "苹方-简 常规体";
  margin: 0px 13px 0px 13px;
}

.wechat_view {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 26px;
}

.wechat_view img {
  width: 48px;
  height: 48px;
  margin-bottom: 10px;
  cursor: pointer;
}

.wechat_view span {
  color: #acacac;
  font-size: 10px;
}
#wxLogin {
  text-align: center;
}
</style>
